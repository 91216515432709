import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, SliceZone, MePlusYou } from '../components'
// import { Layout, SEO, SliceZone } from '../components'
// import website from '../../config/website'

const Container = styled.div`
  margin-top: 3rem;
`

const ProjectAboutContainer = styled.div`
  h2 {
    width: 50%;
  }
  width: 60%;
	@media (max-width: ${props => props.theme.breakpoints.m}) {
		h2 {
      width: 100%;
    }
    width: 100%;
  }
`

const HeroImgContainer = styled.div`
  margin: 5rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
		/* margin-right: -3rem;
    margin-left: -3rem; */
  }
`

const HeroImg = styled.img`
	height: ${props => `${props.dimensions.height}px`};
	width: ${props => `${props.dimensions.width}px`};
  @media (max-width: 768px) {
		width: 100%;
    height: auto;
  }
`

const HeroImgLa = styled(HeroImg)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
`

const HeroImgT = styled(HeroImg)`
  display: none;
  @media (min-width: 769px) and (max-width: 899px) {
    display: block;
  }
`

const HeroImgM = styled(HeroImg)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const Verkefni = ({ data: { prismicProject } }) => {
  const { data } = prismicProject
  const { project_name, project_hero, project_about, body } = data

  return (
    <Layout>
      <Container>
        <ProjectAboutContainer>
          <h2>{project_name.text}</h2>
          <p>{project_about.text}</p>
        </ProjectAboutContainer>
        <HeroImgContainer>
          <HeroImgLa src={project_hero.url} dimensions={project_hero.dimensions}/>
          <HeroImgT src={project_hero.tablet.url} dimensions={project_hero.tablet.dimensions}/>
          <HeroImgM src={project_hero.Mobile.url} dimensions={project_hero.Mobile.dimensions}/>
        </HeroImgContainer>
        <SliceZone allSlices={body}/>
        <MePlusYou/>
      </Container>
    </Layout>
  )
}

export default Verkefni

Verkefni.propTypes = {
  data: PropTypes.shape({
    prismicProject: PropTypes.object.isRequired,
  }).isRequired
}

export const projectQuery = graphql`
query PrismicProjectQuery ($prismicId: String!) {
  prismicProject(prismicId: { eq: $prismicId }) {
    data {
      body {
        ... on PrismicProjectBodyPhotoLeft {
          id
          slice_type
          primary {
            project_info_block {
              text
              html
            }
            project_info_block_image {
              url
              dimensions {
                height
                width
              }
              Tablet {
                dimensions {
                  height
                  width
                }
                url
              }
              Mobile {
                dimensions {
                  height
                  width
                }
                url
              }
            }
          }
        }
        ... on PrismicProjectBodyPhotoRight {
          id
          slice_type
          primary {
            project_info_block_image {
              url
              dimensions {
                width
                height
              }
              Tablet {
                dimensions {
                  height
                  width
                }
                url
              }
              Mobile {
                dimensions {
                  height
                  width
                }
              }
            }
            project_info_block {
              text
              html
            }
          }
        }
        ... on PrismicProjectBodyTextWithProjectDetails {
          id
          slice_type
          primary {
            external_link_to_project {
              link_type
              target
              url
            }
            internal_link_to_project {
              link_type
              target
            }
            project_info_block {
              text
              html
            }
            project_scope {
              text
              html
            }
            year {
              text
            }
          }
        }
        # ... on PrismicProjectBodyQuote {
        #   id
        #   slice_type
        #   primary {
        #     quote_text {
        #       html
        #       text
        #     }
        #     quote_owner {
        #       html
        #       text
        #     }
        #   }
        # }
      }
      project_about {
        text
      }
      project_hero {
        url
        dimensions {
          height
          width
        }
        tablet {
          url
          dimensions {
            height
            width
          }
        }
        Mobile {
          url
          dimensions {
            height
            width
          }
        }
      }
      project_name {
        text
      }
    }
  }
}
`
